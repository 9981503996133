<template>
  <div id="app">
    <router-view/>
  </div>
</template>
<script>
export default {
  data(){
    return {
    }
  },
  methods:{
        
  }
}
</script>
<style lang="stylus">
#app
  height 100%
  width 100%
</style>
