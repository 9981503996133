<template>
  <div>
    <div id="pieChart" style="width:580px;height:580px;"></div>
  </div>
</template>
<script>
import echarts from "echarts";
export default {
  props: {
    resultList: Array
  },
  data() {
    return {};
  },
  mounted() {
    this.initData();
  },
  methods: {
    initData() {
      var pieChart = echarts.init(document.getElementById("pieChart"));
      window.onresize = pieChart.resize;
      window.addEventListener("resize", () => {
        pieChart.resize();
      });
      pieChart.setOption(
        {
          color: [
            "rgb(89,121,249)",
            "rgb(144,241,168)",
            "rgb(244,250,114)",
            "rgb(195,115,114)"
          ],
           tooltip: {
            transitionDuration: 0,
            show: false
          },
          graphic: [
            {
              //环形图中间添加文字
              type: "text", //通过不同top值可以设置上下显示
              left: "center",
              top: "45%",
              style: {
                text: "国家体质测试",
                textAlign: "center",
                fill: "#fff", //文字的颜色
                width: 30,
                height: 30,
                fontSize: 34
              }
            }
          ],

          series: [
            {
              name: "等级",
              type: "pie",
              radius: ["50%", "70%"],
              center: ["50%", "50%"],
              selectedMode: "single",
              data: this.resultList,
              avoidLabelOverlap: false,
               itemStyle: {
                normal: {
                  label: {
                    show: true,
                    fontSize:32,
                    formatter: '{b|{b}}\n{per|{d}%}  ',
                 rich: {
                    per: {
                      fontSize: 20,
                      color: '#5cf2f',
                    }
                 }
                  },
                  labelLine: {
                    show: true,
                    length:10
                  }
                }
               },
              emphasis: {
                itemStyle: {
                  shadowBlur: 10,
                  shadowOffsetX: 0,
                  shadowColor: "rgba(0, 0, 0, 0.5)"
                }
              }
            }
          ]
        },
        true
      );
    }
  },
  watch: {
    resultList: {
      handler(newValue, oldValue) {
        this.initData();
      },
      deep: true
    }
  }
};
</script>