import {
    get,
    post
} from '../utils/ajax'
// 获取测试情况
export const reqTestSituations = (data) => {
    return post('/api/performanceFrom/testSituation', data)
}
// 获取国测数据
export const getNationalTest = (data) => {
  return post('/api/performanceFrom/getNationalTest', data)
}
// 通过学生id获取学生测量数据
export const reqGetDataByStuInfo = (data) => {
    return post('/api/measure/td-measurement/getDataByStuId', data)
}