<template>
  <div class="content-wapper">
    <a href="#" title="数据看板">
      <img :src="dataBoard" alt="" style="margin-left: 914px; position: absolute" />
    </a>
    <div class="general-wapper">
      <div class="top-wapper">
        <div class="left">
          <div class="sportsIndex">
            <span class="score">{{ scoreIndex }}</span>
            <div class="sportsItem">
              <div class="sportBg excellentImg">
                <div class="text">
                  <span>{{ excellentPerson
                  }}</span>
                  <span style="font-size: 32px">人</span>
                </div>
                <el-progress type="circle" :percentage="Number(excellent)" :width="120" :stroke-width="20"
                  color="#39EFA5" style="margin-left: 50px; margin-top: 2px"></el-progress>
              </div>
              <div class="sportBg goodImg">
                <div class="text">
                  <span>{{ goodPerson
                  }}</span>
                  <span style="font-size: 32px">人</span>
                </div>
                <el-progress type="circle" :percentage="Number(good)" :width="120" :stroke-width="20" color="#F8F560"
                  style="margin-left: 50px; margin-top: 2px"></el-progress>
              </div>
              <div class="sportBg passImg">
                <div class="text">
                  <span>{{ passPerson
                  }}</span>
                  <span style="font-size: 32px">人</span>
                </div>
                <el-progress type="circle" :percentage="Number(pass)" :width="120" :stroke-width="20" color="#0086FF"
                  style="margin-left: 50px; margin-top: 2px"></el-progress>
              </div>
              <div class="sportBg nopassImg">
                <div class="text">
                  <span>{{ noPassPerson
                  }}</span>
                  <span style="font-size: 32px">人</span>
                </div>
                <el-progress type="circle" :percentage="Number(noPass)" :width="120" :stroke-width="20" color="#F47053"
                  style="margin-left: 50px; margin-top: 2px"></el-progress>
              </div>
            </div>
          </div>
          <!-- 注册学生 -->
          <div class="centerContent">
            <div class="registered">
              <span>{{ register }}</span>
            </div>
            <div class="sports">
              <span>{{ sports }}</span>
            </div>
            <div class="health">
              <span>{{ health }}</span>
            </div>
          </div>
          <!-- 今日活跃度 -->
          <vueSeamlessScroll class="dayActive" :class-option="classOption" :data="teacherOptions">
            <div class="teacherWapper">
              <div class="teacher-list" v-for="(item, index) in teacherOptions" :key="index">
                <div class="item">
                  <img :src="Fei" alt="" :style="{opacity:(item.isShow == 1?1:0.3)}" />
                  <div class="count-name">
                    <span class="name">{{ item.name }}</span>
                    <span class="count">+{{ item.count }}</span>
                  </div>
                </div>
              </div>
            </div>
          </vueSeamlessScroll>
        </div>
        <!-- 最佳排行榜 -->
        <div class="right">
          <div style="margin-top: 140px">
            <div v-for="(item1, index) in list" :key="index">
              <div v-if="listActive == index" class="animate__animated animate__bounceInRight">
                <span class="tiName">{{ item1.tiName }}</span>
                <div class="list" v-for="(item2, index2) in item1.list" :key="index2" v-show="index2 < 6">
                  <span>{{ item2.stuName }}</span>
                  <span class="result">{{ item2.result }}</span>
                  <span>{{ item2.areaName }}</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="single-wapper">
      <div class="school-page">
        <div class="school-parameter">
          <div class="mea-time">
            {{parseTime(meaData.measureTime)}}
          </div>
          <!-- 校园指数 -->
          <div class="school-index">
            <div class="school-name">{{ currentSchool.areaName }}</div>
            <!-- 环形图 -->
            <pieCharts class="pie-chart" :resultList="currentSchool.reachTheStandardGrade"></pieCharts>
            <div class="result-distribution">
              <div v-for="(item, index) in currentSchool.reachTheStandardGrade" :key="index">
                <div class="excellent-img" v-if="item.level == '优秀'">
                  <span>{{ item.count }}</span>
                </div>
                <div class="good-img" v-if="item.level == '良好'">
                  <span>{{ item.count }}</span>
                </div>
                <div class="pass-img" v-if="item.level == '及格'">
                  <span>{{ item.count }}</span>
                </div>
                <div class="no-pass-img" v-if="item.level == '不及格'">
                  <span>{{ item.count }}</span>
                </div>
              </div>
            </div>
          </div>
          <!-- 学校信息 -->
          <div class="school-info">
            <!-- 学校信息 -->
            <img :src="Fei" class="avatar" />
            <div class="nation-info-wrapper">
              <!-- 个人体态 -->
              <div class="person-guesture">
                <div class="bg-blue-header">
                  <img src="../assets/images/home/person_guesture.png" />
                  <img src="../assets/images/home/person.png" class="person-bg" />
                  <!-- 颈部前倾 -->
                  <div class="jbqq">{{ meaData.neckCenterDistance }}</div>
                  <!-- 肩部内陇 -->
                  <div class="jbnl">{{ meaData.shoulderCenterDistance }}</div>
                  <!-- 双肩高差 -->
                  <div class="sjgc">{{ meaData.shoulderDistance }}</div>
                  <!-- 背部前倾 -->
                  <div class="bbqq">{{ meaData.tuobeiData }}</div>
                  <!-- 跨部前倾 -->
                  <div class="kbqq">{{ meaData.hipCenterDistance }}</div>
                  <!-- 脊椎垂直 -->
                  <div class="jzcz">{{ meaData.jizhuiShoulderThe }}</div>
                  <!-- 肩部内陇 -->
                  <div class="tx">{{ meaData.tuixingData }}</div>
                  <!-- 腿长差 -->
                  <div class="tcc">{{ meaData.unequalLeg }}</div>
                </div>
              </div>
              <!-- 国策成绩 -->
              <div class="nation-result">
                <img src="../assets/images/home/nation_result.png" />
                <div class="course-list" v-for="(item, index) in courseList" :key="index" v-show="index<5">
                  <div class="course-name">
                    {{ item.itemName }}
                  </div>
                  <div class="progress">
                    <div class="number">
                      <div>{{ item.lastHoleScore }}</div>
                      <span>{{ item.average }}</span>
                    </div>
                    <div class="progress-change">
                      <div class="progress-wrapper">
                        <span class="progress1 progress-bg" :style="{ width: `${item.lastHoleScore}%` }"></span>
                        <img :src="arrowBottom" v-if="
                          item.lastSecondScore &&
                          item.lastSecondScore > item.lastHoleScore
                        " class="arrow-bottom-img" />
                        <img :src="arrowTop" v-if="
                          item.lastSecondScore &&
                          item.lastSecondScore < item.lastHoleScore
                        " class="arrow-top-img" />
                      </div>
                      <div class="progress-wrapper">
                        <span class="progress2 progress-bg" :style="{ width: `${item.average}%` }"></span>
                        <img :src="arrowBottom" v-if="
                          item.lastSecondScore &&
                          item.lastSecondScore > item.lastHoleScore
                        " class="arrow-bottom-img" />
                        <img :src="arrowTop" v-if="
                          item.lastSecondScore &&
                          item.lastSecondScore < item.lastHoleScore
                        " class="arrow-top-img" />
                      </div>
                    </div>
                  </div>
                  <div class="tested-total">测试次数:{{ item.number }}次</div>
                  <div class="good">
                    历史最佳:
                    <span class="red"> {{ item.highest }}分 </span>
                  </div>
                </div>
                <div v-show="!courseList.length" class="no-data">
                  <img src="../assets/images/no_content.png" />
                  <div style="color: #666">暂无数据</div>
                </div>
              </div>
              <!-- 个人体态雷达图 -->
              <div class="person-guesture-radar">
                <img src="../assets/images/home/person_guesture.png" />

                <!-- 雷达图 -->
                <radarCharts :sport="sport"></radarCharts>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import pieCharts from "@/components/home/pieCharts";
import radarCharts from "@/components/home/radarCharts";
import {
  reqTestSituations,
  reqGetDataByStuInfo,
  getNationalTest
} from "@/api/home";
import vueSeamlessScroll from "vue-seamless-scroll";
import Fei from "@/assets/images/fei.jpg";
import dataBoard from "@/assets/images/dataBoard.png";
import arrowBottom from "@/assets/images/home/arrow_top.png";
import arrowTop from "@/assets/images/home/arrow_bottom.png";
export default {
  components: {
    pieCharts,
    radarCharts,
    vueSeamlessScroll
  },

  data() {
    return {
      arrowBottom,
      arrowTop,
      dataBoard,
      Fei,
      courseList: [],
      meaData: {},
      currentSchool: {}, //学校列表
      allSchoolList: [], //所有的学校列表
      resultList: [], //成绩分布
      teacherOptions: [],
      courseList: [],
      sport: {
        // endurance: 10,
        // speed: 20,
        // agile: 30,
        // flexible: 40,
        // power: 50
      },
      list: [],
      listActive: 0,
      schoolTimer: 0,
      register: "", //注册学生
      sports: "", //体育记录
      health: "", //卫生记录
      good: "",
      goodPerson: "",
      excellent: "",
      excellentPerson: "",
      pass: "",
      passPerson: "",
      noPass: "",
      noPassPerson: "",
      scoreIndex: ""
    };
  },
  computed: {
    classOption() {
      return {
        step: 1,
        limitMoveNum: 7,
        openTouch: false,
        direction: 2,
        singleWidth: 0
      };
    }
  },
  filters: {},
  created() {
    this.getNationalTest();
    this.getTestSituations(); //获取测试情况
    this.getDataByStuInfo(); //获取学生信息
  },

  methods: {
    // 获取学校信息
    async getTestSituations() {
      let res = await reqTestSituations();
      let { code, data, msg } = res;
      if (code == "200") {
        this.allSchoolList = data;
        this.handleData();
        setInterval(() => {
          this.handleData(this.schoolTimer++);
          if (this.schoolTimer >= this.allSchoolList.length) {
            this.schoolTimer = 0;
          }
        }, 13000);
        setInterval(() => {
          this.getDataByStuInfo();
        }, 16000);
      } else {
        this.$message.error(msg);
      }
    },
    //  处理定时数据
    handleData(idx = 0) {
      this.currentSchool = this.allSchoolList[idx]; //获取学校信息
      if (
        this.currentSchool.reachTheStandardGrade &&
        this.currentSchool.reachTheStandardGrade.length
      ) {
        this.currentSchool.reachTheStandardGrade.forEach(e => {
          e.name = e.level;
          e.value = e.count;
        });
      }
    },
    // 获取国测数据
    async getNationalTest() {
      let res = await getNationalTest();
      let { code, data, msg } = res;
      if (code == "200") {
        this.teacherOptions = data.activeList;
        this.list = data.list;
        this.register = data.register;
        this.sports = data.sports;
        this.health = data.health;
        this.goodPerson = data.goodPerson;
        this.noPassPerson = data.noPassPerson;
        this.excellentPerson = data.excellentPerson;
        this.passPerson = data.passPerson;
        this.excellent = data.excellent.slice(0, data.excellent.length - 1);
        this.good = data.good.slice(0, data.good.length - 1);
        this.pass = data.pass.slice(0, data.pass.length - 1);
        this.noPass = data.noPass.slice(0, data.noPass.length - 1);
        this.scoreIndex = data.score;
        setInterval(() => {
          this.listActive++;
          if (this.listActive >= this.list.length) {
            this.listActive = 0;
          }
        }, 10000);
      } else {
        this.$message.error(msg);
      }
    },
    // 获取学生信息
    async getDataByStuInfo() {
      let res = await reqGetDataByStuInfo();
      let { code, data, msg } = res;
      if (code == "200") {
        let { po, analysis, sport } = data;
        this.hanldeData(po);
        this.courseList = analysis.statistics;
        this.sport = sport;
      } else {
        // this.$message.error(msg);
      }
    },
    // 固定成一个小数
    toFixedOne(data) {
      if (data) {
        return Number(data).toFixed(1);
      } else {
        return "0.0";
      }
    },

    // 处理返回的数据.处理成1位小数
    hanldeData(data) {
      let {
        neckCenterDistance,
        shoulderCenterDistance,
        shoulderDistance,
        hipCenterDistance,
        tuobeiData,
        jizhuiShoulderThe,
        tuixingData,
        unequalLeg
      } = data;
      neckCenterDistance = this.toFixedOne(neckCenterDistance);
      shoulderCenterDistance = this.toFixedOne(shoulderCenterDistance);
      shoulderDistance = this.toFixedOne(shoulderDistance);
      hipCenterDistance = this.toFixedOne(hipCenterDistance);
      tuobeiData = this.toFixedOne(tuobeiData);
      jizhuiShoulderThe = this.toFixedOne(jizhuiShoulderThe);
      tuixingData = this.toFixedOne(tuixingData);
      unequalLeg = this.toFixedOne(unequalLeg);
      this.meaData = {
        ...data,
        neckCenterDistance,
        shoulderCenterDistance,
        shoulderDistance,
        hipCenterDistance,
        tuobeiData,
        jizhuiShoulderThe,
        tuixingData,
        unequalLeg
      };
    }
  }
};
</script>
<style lang="scss" >
.el-progress__text {
  color: #fff !important;
  font-size: 26px !important;
}
.el-progress-circle__track {
  stroke: rgba(50, 220, 152, 0.18) !important;
}
</style>
<style lang="scss" scoped>
.school-page {
  .school-parameter {
    display: flex;
    // margin-top: 20px;
    position: relative;
    .mea-time {
      position: absolute;
      right: 100px;
      top: 44px;
      color: #fff;
      font-size: 34px;
    }
    .school-index {
      width: 918px !important;
      flex: 0 0 918px;
      height: 846px;
      margin-right: 48px;
      background: url("../assets/images/home/school_index.png") center center
        no-repeat;
      background-size: 100% 100%;
      position: relative;
      margin-top: 14px;
      .school-name {
        position: absolute;
        color: #fff;
        left: 210px;
        top: 28px;
        text-shadow: 0px 0px 6px #0cd2cb;
        font-size: 34px;
      }
      .pie-chart {
        position: absolute;
        left: 170px;
        top: 112px;
      }
      .result-distribution {
        display: flex;
        justify-content: space-around;
        align-items: center;
        color: #ffffff;
        line-height: 18px;
        font-size: 12px;
        width: 100%;
        position: absolute;
        bottom: 60px;
        padding: 0 40px;
        box-sizing: border-box;
        div {
          width: 182px;
          height: 70px;
          line-height: 70px;
          &.excellent-img {
            background: url("../assets/images/home/excellent.png");
          }
          &.good-img {
            background: url("../assets/images/home/good.png");
          }
          &.pass-img {
            background: url("../assets/images/home/pass.png");
          }
          &.no-pass-img {
            background: url("../assets/images/home/nopass.png");
          }

          span {
            display: inline-block;
            color: #5cf2fc;
            font-size: 42px;
            text-shadow: 0px 0px 4px #4fdee5;
            margin-left: 100px;
          }
        }
      }
    }
    .school-info {
      flex: 1;
      width: 2842px;
      height: 878px;
      background: url("../assets/images/home/ehsr_bg.png") center center
        no-repeat;
      position: relative;
      .avatar {
        position: absolute;
        left: 8px;
        top: 31px;
        width: 80px;
        height: 80px;
      }

      .nation-info-wrapper {
        margin-top: 125px;
        display: flex;
        .person-guesture {
          width: 850px;
          text-align: center;
          position: relative;

          .person-bg {
            position: relative;
            top: -35px;
          }
          .bg-blue-header {
            position: relative;
            & > div {
              position: absolute;
              color: #fff;
              font-size: 30px;
            }
          }
          .jbqq {
            left: 260px;
            top: 124px;
          }

          .jbnl {
            right: 248px;
            top: 116px;
          }

          .sjgc {
            left: 175px;
            top: 280px;
          }

          .bbqq {
            right: 180px;
            top: 264px;
          }

          .kbqq {
            left: 165px;
            bottom: 306px;
          }

          .jzcz {
            right: 165px;
            bottom: 328px;
          }

          .tx {
            left: 233px;
            bottom: 154px;
          }

          .tcc {
            right: 240px;
            bottom: 150px;
          }
        }
      }
      .nation-result {
        flex: 1;
        text-align: center;
        .course-list {
          font-size: 24px;
          display: flex;
          width: 930px;
          align-items: center;
          justify-content: space-between;
          padding: 20px 0;
          cursor: pointer;
          color: #5aeef8;
          margin: 0 auto;
          text-align: left;
          &.active {
            background: #f5f7ff;
          }

          .course-name {
            width: 100px;
            text-align: center;
            margin-left: 10px;
            color: #faa54dff;
          }
          .tested-total {
            width: 200px;
            text-align: left;
          }
          .good {
            width: 210px;
            .red {
              color: #faa54dff;
              font-family: LucidaCalligraphy-Italic, LucidaCalligraphy;
            }
          }
          .progress {
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            .number {
              display: inline-block;
              width: 65px;
              text-align: center;
            }
            .progress-change {
              width: 80%;
              box-sizing: border-box;
              padding-left: 15px;
              line-height: 40px;
              overflow: hidden;
              .progress-wrapper {
                width: 100%;
                height: 40px;
                float: left;
                overflow: hidden;
              }
              .arrow-bottom-img {
                position: relative;
                top: 2px;
                left: 3px;
              }
              .arrow-top-img {
                position: relative;
                top: 5px;
              }
              span.progress-bg {
                display: inline-block;
                height: 18px;
                position: relative;
                top: 3px;

                &.progress1 {
                  background-color: #32dc98;
                }
                &.progress2 {
                  background-color: #87ffcf;
                }
              }
              >>> .el-progress {
                .el-progress-bar__innerText {
                  color: transparent;
                }
              }
            }
          }
        }
        .no-data {
          margin: 50px auto;
          text-align: center;
        }
      }
      .person-guesture-radar {
        width: 850px;
        flex: 0 0 850px;
        text-align: center;
      }
    }
  }
}
.content-wapper {
  background: url("../assets/images/dataBg.png") center center no-repeat;
  background-size: 100% 100%;
  .general-wapper {
    height: 900px;
    padding: 204px 48px 48px;
    .top-wapper {
      display: flex;
      .left {
        display: flex;
        width: 2388px;
        flex-direction: column;
        .sportsIndex {
          height: 274px;
          width: 100%;
          position: relative;
          background: url("../assets/images/sportsIndex.png") center center
            no-repeat;
          background-size: 100% 100%;
          .score {
            display: inline-block;
            position: relative;
            top: 140px;
            left: 17%;
            color: #fff;
            font-size: 56px;
          }
          .sportsItem {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            margin-left: 720px;
            .sportBg {
              background-size: 100% 100%;
              width: 362px;
              height: 148px;
              margin-left: 48px;
              color: #fff;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              .text {
                color: #fff;
                font-size: 36px;
                margin-top: 50px;
                position: relative;
                left: 40px;
                width: 140px;
              }
            }
            .excellentImg {
              background: url("../assets/images/excellentT.png") center center;
            }
            .goodImg {
              background: url("../assets/images/goodT.png") center center;
            }
            .passImg {
              background: url("../assets/images/passT.png") center center;
            }
            .nopassImg {
              background: url("../assets/images/noPassT.png") center center;
            }
          }
        }
        .centerContent {
          height: 274px;
          width: 100%;
          display: flex;
          margin-top: 34px;
          font-size: 56px;
          align-items: center;
          .registered {
            width: 772px;
            height: 100%;
            background: url("../assets/images/registered.png") center center
              no-repeat;
            position: relative;
            background-size: 100% 100%;
            span {
              position: absolute;
              top: 138px;
              right: 29%;
              color: #fff;
            }
          }
          .sports {
            width: 772px;
            height: 100%;
            background: url("../assets/images/sports.png") center center
              no-repeat;
            position: relative;
            background-size: 100% 100%;
            margin-left: 48px;
            span {
              position: absolute;
              top: 138px;
              right: 30%;
              color: #fff;
            }
          }
          .health {
            width: 772px;
            height: 100%;
            margin-left: 48px;
            position: relative;
            background: url("../assets/images/health.png") center center
              no-repeat;
            background-size: 100% 100%;
            span {
              position: absolute;
              top: 138px;
              right: 29%;
              color: #fff;
            }
          }
        }
        .dayActive {
          white-space: nowrap;
          flex-direction: row;
          height: 304px;
          width: 100%;
          margin-top: 34px;
          overflow: hidden;
          align-items: center;
          justify-content: flex-start;
          background: url("../assets/images/dayActive.png") center center
            no-repeat;
          background-size: 100% 100%;
          position: relative;
          .teacherWapper {
            margin-top: 34px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            .teacher-list {
              width: 352px;
              height: 144px;
              flex: none;
              text-align: center;
              white-space: nowrap;
              overflow: hidden;
              margin-left: 48px;
              background: url("../assets/images/teacherActive.png") center
                center no-repeat;
              margin-top: 90px;
              background-size: 100% 100%;
              .item {
                display: flex;
                img {
                  width: 106px;
                  height: 106px;
                  margin-left: 35px;
                  margin-top: 15px;
                }
                .count-name {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  .name {
                    color: #fff;
                    font-size: 32px;
                    margin-top: -45px;
                    margin-left: 48px;
                  }
                  .count {
                    color: #faa54d;
                    font-size: 40px;
                    margin-top: 54px;
                    margin-left: -35px;
                  }
                }
              }
            }
          }
        }
      }
      .right {
        width: 1308px;
        background: url("../assets/images/rankingList.png") center center
          no-repeat;
        background-size: 100% 100%;
        margin-left: 48px;
        .tiName {
          position: absolute;
          margin: -115px 450px;
          font-size: 34px;
          display: inline-block;
          width: 500px;
          text-align: center;
          color: #fff;
        }
        .list {
          display: flex;
          justify-content: space-around;
          align-items: center;
          width: 922px;
          height: 104px;
          color: #fff;
          font-size: 32px;
          margin-top: 23px;
          padding-left: 340px;
          .result {
            color: #faa54d;
            font-size: 40px;
          }
        }
      }
    }
  }
  .single-wapper {
    padding: 0 48px 160px;
  }
}
</style>