<template>
<div>
  <div id="radarChart" :style="{ width: '800px', height: '650px' }"></div>
</div>
  
</template>

<script>
import echarts from "echarts";
export default {
  props: {
    sport: Object
  },
  data() {
    return {
      
    };
  },
 
  methods: {
    initRadar() {
      let { endurance, speed, agile, flexible, power } = this.sport;
      let myChart = echarts.init(document.getElementById("radarChart"));
      var indicator = [
        {
          name: "耐力",
          max: 100,
          value:endurance
        },
        {
          name: "速度",
          max: 100,
          value:speed
        },
        {
          name: "敏捷",
          max: 100,
          value:agile
        },
        {
          name: "柔韧",
          max: 100,
          value: flexible
        },
        {
          name: "力量",
          max: 100,
          value: power
        }
      ];
      myChart.setOption({
        tooltip: {
          transitionDuration: 0,
          show: false
        },
        radar: {
          name: {
            formatter: (a) => {
              var obj = {};
              indicator.forEach((item) => {
                if (item.name == a) {
                  obj.name = item.value;
                }
              });
              return `{a|${a}}\n{b|${obj.name}}`;
            },
            rich: {
              a: {
                color: "#FAA54D",
                fontSize: 26,
                lineHeight: 50
              },
              b: {
                color: "#fff",
                align: "center",
                fontSize: 36,
              }
            }
          },
          grid: {
          containLabel: true
        },
          axisLine: {
            lineStyle: {
              color: "#32DC98",
              width: 0.5
            }
          },
          splitArea: {
            areaStyle: {
              color: ["transparent"]
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              width: 0.5,
              color: ["#32DC98"]
            }
          },
          calculable: true,
          center: ["50%", "50%"],
          radius: 200,
          indicator: indicator
        },
        series: [
          {
            type: "radar",
            symbol: "circle",
            symbolSize: 4,
            itemStyle: {
              normal: {
                color: "rgba(75, 138, 253, 1)"
              }
            },
            areaStyle: {
              opacity: 0.6,
              color: "rgba(250, 165, 77, 0.31)"
            },
            lineStyle: {
              normal: {
                color: "rgba(250, 165, 77, 1)"
              }
            },
            data: [
              {
                name: "运动能力",
                value: [endurance, speed, agile, flexible, power]
              }
            ]
          }
        ]
      });
    }
  },
   mounted() {
    this.initRadar();
  },
  watch: {
    sport: {
      handler(newValue, oldValue) {
        this.$nextTick(()=>{
          this.initRadar();
        })
      },
      deep: true
    }
  }
};
</script>
